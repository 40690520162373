import React, { useState, useEffect, useContext, useMemo } from "react";

import { Grid, _ } from "gridjs-react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Autocomplete, TextField } from "@mui/material";
const CustomerStatementReport = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState(8);
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [Custom, setCustom] = useState("text");
  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [billAmount, setBillAmount] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [helper, setHelper] = useState(false);

  const [modal_delete, setmodal_delete] = useState(false);
  const [CustometINFO, setCustometINFO] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  document.title = "Customer Statement Report";

  const {
   

    cardList,
   
    fetchCustomers,
    customers,fetchCustomerStatementReport, customerStatement,isLoading,AnalogCustomersWithCards,fetchAnaloghCustomersWithCardNumbers
  } = useContext(LoginContex);

  useEffect(() => {

    fetchCustomers();
  
   
  }, []);


  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  //console.log(customerStatement)
  const columns = useMemo(
    (row) => [
      // {
      //   Header: "ActionDate",
      //   accessor: "createdAt",
      //   filterable: false,
      //   Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      // },
      {
        Header: "Start Date",
        accessor: "startetime",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "End Date",
        accessor: "endtime",
        filterable: false,
        Cell: (cell) => <>{handleValidDate(cell.value)}</>,
      },
      {
        Header: "Card No",
        accessor: "terminalid",
        filterable: false,
      },
      {
        Header: "Product",
        accessor: "productname",
        filterable: false,
      },
      
    ]
    // [handleCustomerClick]
  );
  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  //console.log(selectedOption?._id)
  const filterThroughDate = () => {
    setHelper(true);
   
    fetchCustomerStatementReport(selectedOption?._id);
  };

  //console.log(customer);
  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);
    
    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}`,
    };

    CustomersArr.push(dropdownList);
  }
  

  const customerCards = [];

  for (let i = 0; i < cardList.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: cardList?.[i]?.dataCustomers?._id,
      cardNumber: cardList?.[i]?.cardNumber,
      receiverType: cardList?.[i]?.datareceivers?.itemType,
      label: cardList?.[i]?.dataCustomers?.customerName,
    };

    customerCards.push(dropdownList);
  }





  const getOptionSelected = (option, value) => {
    //console.log(option);
    //console.log(value);
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };
  //console.log(getOptionSelected());
  //console.log(selectedOption);
  const onInputChange2 = (event, value, reason) => {
    //console.log(value);
    //console.log(event);
    setCustometINFO(value);
    fetchAnaloghCustomersWithCardNumbers(value);

    const matchingOption = makeItArray2.find(
      (option) =>
        option.customers.toLowerCase() ==
        value.substring(0, value.indexOf("-")).toLowerCase()
    );

    setSelectedOption(matchingOption);
  };

  const makeItArray2 = [];

  for (let i = 0; i < AnalogCustomersWithCards?.length; i++) {
    // //console.log("from: " + formattedDate);
    let singleArr = {
      customers: AnalogCustomersWithCards[i]?.customerName,
      customerPhone: AnalogCustomersWithCards[i]?.customerPhone,
      _id: AnalogCustomersWithCards[i]?._id,
      customerAddress: AnalogCustomersWithCards[i]?.customerAddress,
      districtName: AnalogCustomersWithCards[i]?.districtName,
    };

    makeItArray2.push(singleArr);
  }
  //console.log(makeItArray2);

  const CvsData = [];
for (let i = 0; i < customerStatement?.length; i++) {
  // //console.log(customerStatement[i]._id);

 
    var dropdownList = {
      startDat: ` ${moment(customerStatement[i]?.startetime).utc().format("DD-MM-YYYY")}`,
      endDate: ` ${moment(customerStatement[i]?.endtime).utc().format("DD-MM-YYYY")}`,
      cardNumber: ` ${customerStatement?.[i]?.terminalid}`,
      productname: ` ${customerStatement?.[i]?.productname}`,
     
     
     
    
      
     

    };
 
 

  CvsData.push(dropdownList);
}

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(CvsData?.[0] || []);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// ** Downloads CSV
function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Customer Statement Report " pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autoa">
                      <div>
                        <ToastContainer />
                        <div className="">
                          <h4 className="card-title mb-0">Customer Statement Report</h4>
                          {/* <Button>Go Back</Button> */}
                          <div className="form-label" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={()=>{
                              downloadCSV(CvsData)
                            }}
                            type="button"
                            //  id="create-btn"
                          > 
                            <i className="fa fa-print mx-2" aria-hidden="true"> </i>  
                            Download Excel
                          </Button>
                        </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                    <Col md={3}>
                        {/* <div>
                          <label>
                            Customers <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={CustomersArr}
                            onChange={(choice) => setCustomerId(choice)}
                            required
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                // text: "orangered",
                                // primary25: "hotpink",
                                // primary: "black",
                              },
                            })}
                          />
                        </div> */}
                         <div className="mb-3">
                             
                             <Label className="form-label">
                               Customer <span className="text-danger">*</span>
                             </Label>
                             <Autocomplete
                               disabled={isLoading}
                               disablePortal
                               id="combo-box-demo"
                               size="small"
                               options={makeItArray2}
                               getOptionSelected={getOptionSelected}
                               getOptionLabel={(option) => {
                                 return `${option.customers?.toString()}-${option.customerPhone?.toString()}`;
                               }}
                               value={selectedOption}
                               onInputChange={onInputChange2}
                               renderInput={(params) => (
                                 <TextField
                                   {...params}
                                   label="Search Customer..."
                                 />
                               )}
                             />
                           </div>
                      </Col>
                      {/* <Col md={3}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Start Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setStart(date)}
                            value={start}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            End Date
                          </Label>
                          <Flatpickr
                            className="form-control"
                            required
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setEnd(date)}
                            value={end}
                          />
                        </div>
                      </Col>
                      */}
                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Customer{" "}
                          </Button>
                        </div>
                      </Col>
                      {/* <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={clearFilter}
                            type="button"
                            //  id="create-btn"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Clear filter{" "}
                          </Button>
                        </div>
                      </Col>  */}
                    </Row>

                    <TableContainer
                      columns={columns}
                      data={customerStatement || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // handleCustomerClick={handleCustomerClicks}
                      // isCustomerFilter={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default CustomerStatementReport;
