module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
  // Development URL
  //  API_URL: "http://10.111.10.13:5656/api/v1/",


    // Production URL
    API_URL: "https://asal24.tabaarak.net/api/v1/",

  },
};

// abdimalik5
// aBD@5510$$



