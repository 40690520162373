import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, _ } from "gridjs-react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Autocomplete, TextField } from "@mui/material";

const CardInfo = () => {
  const [CustometINFO, setCustometINFO] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [filteredCardInfo, setFilteredCardInfo] = useState([]);
  document.title = "Card Information";

  const { fetchCardInfo, CardInformation, isLoading, AllCards, fetchAllCards } =
    useContext(LoginContex);

  useEffect(() => {
    fetchAllCards("");
  }, []);

  useEffect(() => {
    setFilteredCardInfo(CardInformation);
  }, [CardInformation]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Card Number",
        accessor: (row) => row?.cardNumber || "N/A",
        filterable: false,
      },
      {
        Header: "Customer Name",
        accessor: (row) => row?.customerName || "N/A",
        filterable: false,
      },
      {
        Header: "Customer Phone",
        accessor: (row) => row?.customerPhone || "N/A",
        filterable: false,
      },
      {
        Header: "Customer User",
        accessor: (row) => row?.customerUser || "N/A",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: (row) => {
          if (row.customerInfo) {
            return "Sold";
          } else if (row) {
            return row.productAvailability ? "Available" : "Unavailable";
          }
          return "Unknown";
        },
        filterable: false,
      },
    ];
  }, []);

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const filterThroughDate = async () => {
    if (selectedOption?.cardNumber) {
      setFilteredCardInfo([]); // Clear the table while fetching new data
      const data = await fetchCardInfo(selectedOption.cardNumber);
      setFilteredCardInfo(data || []);
    } else {
      showToastMessage("Please select a card number.");
    }
  };

  const getOptionSelected = (option, value) => {
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };

  const onInputChange2 = (event, value) => {
    setCustometINFO(value);
    fetchAllCards(value);

    const matchingOption = makeItArray2.find(
      (option) =>
        option.cardNumber.toLowerCase() ===
        value.substring(0, value.indexOf("-")).toLowerCase()
    );
    setSelectedOption(matchingOption);
  };

  const onChange = (event, value) => {
    setSelectedOption(value);
  };

  const makeItArray2 = [];
  for (let i = 0; i < AllCards?.length; i++) {
    let singleArr = {
      cardNumber: AllCards[i]?.cardNumber,
      _id: AllCards[i]?._id,
    };
    makeItArray2.push(singleArr);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Card Information " pageTitle="Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-autoa">
                      <div>
                        <ToastContainer />
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div id="Purchasestable">
                    <Row className="mb-5">
                      <Col md={3}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Card Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Autocomplete
                            disabled={isLoading}
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            options={makeItArray2}
                            getOptionSelected={getOptionSelected}
                            getOptionLabel={(option) => {
                              return `${option.cardNumber?.toString()}`;
                            }}
                            value={selectedOption}
                            onInputChange={onInputChange2}
                            onChange={onChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Card Number..."
                              />
                            )}
                          />
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="form-label">
                          <Button
                            color="success"
                            className=" add-btn mt-4"
                            onClick={filterThroughDate}
                            type="button"
                          >
                            <i className="  ri-file-list-line align-bottom me-1 "></i>{" "}
                            Filter By Card{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <TableContainer
                      columns={columns}
                      data={filteredCardInfo || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CardInfo;